import React from "react"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import AuthorsSection from "@src/components/AuthorsSection"
import LoremIpsum from "@src/components/LoremIpsum"

const TestPage = () => {
  const authorBox = [
    {
      id: "1",
      name: "NerdWallet",
      link: "https://www.nerdwallet.com/blog/author/admin/",
      contactEmail: "articles@nerdwallet.com",
      twitter: "https://twitter.com/NerdWallet",
      facebook: "https://facebook.com/nerdwallet",
      linkedin: "https://www.linkedin.com/company/nerdwallet/",
      marketplaceBio: "Follow NerdWallet on social media for updates",
      description: "Follow NerdWallet on social media for updates",
      avatarUrls: [
        {
          size: "24",
          url: "https://assets.nerdwallet.com/blog/wp-content/uploads/2019/08/admin-user-avatar-70x70.png",
        },
        {
          size: "48",
          url: "https://assets.nerdwallet.com/blog/wp-content/uploads/2019/08/admin-user-avatar-70x70.png",
        },
        {
          size: "96",
          url: "https://assets.nerdwallet.com/blog/wp-content/uploads/2019/08/admin-user-avatar-160x160.png",
        },
      ],
    },
    {
      id: "2",
      name: "NerdWallet",
      link: "https://www.nerdwallet.com/blog/author/admin/",
      contactEmail: "articles@nerdwallet.com",
      twitter: "https://twitter.com/NerdWallet",
      facebook: "https://facebook.com/nerdwallet",
      linkedin: "https://www.linkedin.com/company/nerdwallet/",
      marketplaceBio: "Follow NerdWallet on social media for updates",
      description: "Follow NerdWallet on social media for updates",
      avatarUrls: [
        {
          size: "24",
          url: "https://assets.nerdwallet.com/blog/wp-content/uploads/2019/08/admin-user-avatar-70x70.png",
        },
        {
          size: "48",
          url: "https://assets.nerdwallet.com/blog/wp-content/uploads/2019/08/admin-user-avatar-70x70.png",
        },
        {
          size: "96",
          url: "https://assets.nerdwallet.com/blog/wp-content/uploads/2019/08/admin-user-avatar-160x160.png",
        },
      ],
    },
  ]
  const title = "Test Page"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }

  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={
        <HeaderContent
          title={title}
          authors={authorBox}
          breadcrumb={breadcrumb}
        />
      }
      footer={<Footer />}
    >
      <LoremIpsum />
      <AuthorsSection authorBoxes={authorBox} />
    </Page>
  )
}

export default TestPage
